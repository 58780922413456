import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    routes: [{
            path: '/',
            component: () => import('../views/index.vue'),
        } ,{
            path: '/rem',
            component: () => import('../views/rem.vue'),
        }  
        // ,{
        //     path: '/Ranking',
        //     component: () => import('../views/Ranking.vue'),
        // } 
    ],
});